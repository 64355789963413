.column {
  margin-bottom: 15px;
}

.boxCSV {
  margin-top: 27px;
  display: flex;
  justify-content: flex-end;
  max-width: 60px;
  margin-bottom: 15px;
}
