.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  &.hide {
    opacity: 0.2;
  }
  .title {
    margin-bottom: 20px;
  }
  .form {
    width: 100%;
    max-width: 80%;
  }
  .submitBox {
    text-align: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .createAccountBox {
    text-align: center;
    width: 100%;
    margin-top: 20px;
  }
  .forgotPasswordLink {
    cursor: pointer;
  }
  .divisor {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.loadingManager {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  margin-top: -40px;
}
