.icon {
  width: 26px;
  height: 36px;
  display: block;

  &.disabled {
    opacity: 0.4;

    &:hover {
      cursor: default;
    }
  }
}
