.container {
  margin-bottom: 15px;
  margin-top: 15px;
  .statusButton {
    > p {
      text-align: center !important;
    }
    &.active {
      cursor: default;
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
}
