@import "../../../../../shared/styles/colors.scss";

.container {
  margin-bottom: 15px;
}

.cardElementContainer {
  padding: 10px 5px;
  border: 1px solid $gray;
  box-shadow: inset 1px 1px 2px -1px $gray;

  &.error {
    border: 1px solid $red;
    box-shadow: none;
  }
}
