.container {
  display: flex;
}

.tooltip {
  width: 400px;
}

.icon {
  margin-top: 3px;
}
