@import "../../../../shared/styles/colors.scss";

.lotBox {
  margin-bottom: 15px;
  width: 100%;

  .editLink {
    font-size: 24px;

    &:hover svg {
      color: $gray_light;
    }
  }

  .lotInfo {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .address,
  .visibilityToggle p {
    line-height: 24px;
  }

  .photo {
    width: 140px;
    background: $gray_light;
  }

  .visibilityToggle {
    border-top: 1px solid $gray;
    margin-top: 10px;
    padding-top: 10px;
    display: flex;
  }
}
