@import "../../../styles/colors.scss";

/* DROPDOWN */
.dropdownContent {
  position: absolute;
  top: 65px;
  left: 0;
  width: 220px;
  z-index: 81;

  @media print {
    display: none !important;
  }

  .citifydLogo {
    width: 22px;
    height: 22px;
    padding: 4px;
    background: $gray_darker;
    border-radius: 3px;
  }
}

@media screen and (max-width: 600px) {
  .dropdownContent {
    left: 5px;
  }
}
