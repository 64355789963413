.icon {
  padding-right: 3px;
}

.container {
  padding: 20px 0;
}

.searchContainer {
  display: flex;
}
