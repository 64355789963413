.saveButton {
  @media (max-width: 500px) {
    width: 100%;
    margin: 0px 10px;
  }
}

.routerLink {
  align-self: center;
  display: inline-flex;
  @media (max-width: 500px) {
    display: none;
  }
}
.icon {
  align-self: center;
}
