@import "../../../styles/colors.scss";

.siteFooterWrap {
  background: $gray_darker;
  color: $white;
  padding: 14px 0;
  margin-top: 40px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;

  .footer {
    a:link {
      color: $white;
      text-decoration: none;
    }

    a:visited {
      color: $white;
    }

    a:hover,
    a:focus {
      color: $white;
      text-decoration: underline;
    }

    a:active {
      color: $white;
    }
  }

  @media screen and (max-width: 1170px) {
    .footer {
      height: 20px;

      .language-selector {
        position: absolute;
        right: 85px;
        top: 20px;
      }
    }
  }
}
