.component-loading-manager {
  text-align: center;
  margin-bottom: 30px;

  &__message {
    margin-bottom: 10px;
  }

  &--loading {
    .component-loading-manager__message {
      margin-top: 30px;
    }
  }
}
