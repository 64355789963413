@import "../../../../shared//styles/colors.scss";

.wrapper {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid $gray;
}

.button {
  margin-left: 0 !important;
}
