@import "../../../styles/colors.scss";

/* HEADER */
.headerWrap {
  background: $gray_darker;
  color: $white;

  @media print {
    background: $white;
    color: $black;
  }

  .header {
    .logo {
      float: left;
      width: 60px;
      height: auto;
    }

    .back {
      float: right;
      position: relative;
      top: 27px;
    }

    /* USER DROPDOWN */
    .navLeft {
      float: left;
      position: relative;

      .userDropdown {
        display: inline-block;
      }

      .userDropdownToggle {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 7px 13px;
        height: 60px;
        font-size: 13px;
        cursor: pointer;

        user-select: none;

        svg {
          margin-left: 4px;
        }

        &:hover {
          background: $gray_dark;
        }

        @media print {
          i {
            display: none;
          }
        }
      }
    }
  }
}

/* MOBILE BUTTON MENU */
@media screen and (max-width: 660px) {
  .menuToggle {
    display: block;
    background: transparent;
    border: 0px;
    color: $white;
    float: right;
    padding: 15px;
    font-size: 25px;
  }

  .menuToggle:hover {
    cursor: pointer;
  }

  .headerWrap > div {
    padding: 0 !important;
  }
}

@media screen and (min-width: 661px) {
  .menuToggle {
    display: none;
  }
}
