@import "../../../styles/colors.scss";

.colorSelector {
  display: flex;
  flex-direction: column;
  .buttons {
    display: flex;
    .button {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      margin-right: 4px;
      &.active {
        color: $white;
      }
    }

    .icon {
      background: #ffffffc9;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      padding: 6px;
      margin: 0;
    }
  }
}
