@import "../../../styles/colors.scss";

.phoneNumber {
  display: flex;
  flex-wrap: wrap;
  flex: 1;

  .label {
    min-width: 100%;

    &.error {
      color: $red;
    }
  }

  .phoneCountry {
    white-space: nowrap;

    &.disabled .selectedCountry {
      cursor: default;
      background: $gray_lighter;
    }
  }

  .selectedCountry {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-right: 10px;
    box-shadow: inset 1px 1px 2px -1px $gray;
    border: 1px solid $gray;
    background: $white;
    font-size: 14px;
    padding: 5px 8px;

    > span {
      margin-right: 5px;
    }

    svg {
      float: none;
    }
  }

  .countryOptions {
    position: absolute;
    max-height: 200px;
    overflow: auto;
    background: $white;
    box-shadow: 2px 2px 5px 2px rgba(69, 68, 63, 0.3);
    border-radius: 5px;
    margin-top: 4px;
    padding: 6px;
    z-index: 1;
    min-width: 275px;

    li {
      border-radius: 4px;
      padding: 5px 14px;

      p {
        font-size: 13px;
        line-height: 110%;
      }

      &:hover {
        background: $gray_lighter;
      }

      &.selected {
        background: $primary;
        color: $black;
      }
    }
  }

  .phoneContainer {
    flex: 1;
  }
}
