@import "../../../shared/styles/colors.scss";
.daySelector {
  margin: 0 20px;
  width: 200px;
}

.noRules {
  margin: 50px 0 30px 0;
}

.rateBoard {
  position: relative;
  margin-top: 20px;
  overflow: hidden;

  &:before,
  &:after {
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
  }

  &:before {
    left: 0;
    width: 20px;
    background: linear-gradient(90deg, $white 30%, rgba(255, 255, 255, 0) 100%);
  }

  &:after {
    content: "";
    width: 25px;
    right: 0;
    height: 100%;
    position: absolute;
    background: linear-gradient(
      270deg,
      $white 30%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

.scrollWrapper {
  overflow-x: scroll;
  overflow-y: hidden; // fixes vertical scroll on Safari, that occurs when overflow-x is scroll ¯\_(ツ)_/¯
}

.tableWrapper {
  display: inline-block;
  padding: 0 20px;
}
