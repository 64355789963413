@import "../../shared/styles/colors.scss";
.content {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 2px 2px 5px 2px rgba(69, 68, 63, 0.3);
  background: $white;
  margin-bottom: 100px;
}

.tableRef {
  height: 45px;

  > div > div > div {
    margin-top: 20px !important;
  }
}
