.column {
  margin-bottom: 15px;
}

.download {
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
}

.csv {
  padding-right: 10px;
}
