@import "../../../shared/styles/colors";

.errorMessage {
  margin-top: 6px;
}

.endTypeColumn {
  display: flex;
  flex-direction: column;
}

.priceColumn {
  input {
    width: 100%;
  }
}

.endTimeColumn {
  input {
    width: 90px;
    margin-bottom: 5px;
  }
}

.endDurationColumn {
  display: flex;

  span {
    display: block;
    margin-top: 5px;
  }

  input {
    width: 50px;
    &:first-of-type {
      margin-right: 5px;
    }
  }
}

.timeColumn {
  .timeFields {
    display: flex;
    .field {
      width: 75px;
    }
  }

  .separator {
    padding: 8px 6px;
  }
}

.actionsColumn {
  width: 1%;
  white-space: nowrap;

  .actionsButtons {
    cursor: pointer;
    position: relative;
    width: 31px;
    height: 31px;
    border-radius: 100%;
    border: 1px solid $gray_dark;
    padding: 0;

    span {
      display: block;
      position: absolute;
      top: 5px;
      width: 30px;
      text-align: center;
    }
  }

  .actionsDropdown {
    position: absolute;
    width: 140px;
    background: $white;
    border: 1px solid rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    z-index: 1;
    padding: 4px 0;

    a {
      display: block;
      color: $gray_darker;
      text-decoration: none;
      padding: 6px 14px;

      &:hover,
      &:focus {
        background: $primary;
        color: $white;
      }
    }
  }
}
