.downloadPdfReceipt {
  cursor: pointer;
}

.ticketHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 8px;
}

.informationHeader {
  margin-bottom: 10px !important;
}

.vehicleColor {
  display: flex;
  align-items: center;
}

.text {
  padding-top: 10px;
}

.payment {
  display: flex;
}
