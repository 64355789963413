@import "../../../styles/colors.scss";

.languageOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999;

  .dialog {
    width: 200px;
    background: $white;
    text-align: center;
    color: $black;
    font-size: 14px;
    border-radius: 10px;
    margin: 40px auto 0 auto;
    padding: 20px;

    p {
      margin: 0;
      padding-top: 20px;
    }
  }
}

.languageSelector {
  position: relative;
  float: right;

  button {
    border: none;
    background: none;
    font-size: 14px;
    text-transform: uppercase;
    color: $white;
    cursor: pointer;
  }

  .languagesContent {
    width: 200px;
    position: absolute;
    right: 0px;
    bottom: 38px;
    transition: 0.18s linear all;
    opacity: 0;
    margin-bottom: -14px;

    &.hide {
      display: none;
    }

    &.enter {
      opacity: 1;
      margin-bottom: 0;
    }
  }
}

@media print {
  .languageSelector {
    display: none;
  }
}
