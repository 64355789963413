@import "../../../../../../shared/styles/colors.scss";

.content {
  padding: 20px;

  table tbody tr:not(:last-child) {
    border-bottom: 1px solid $white;
  }

  table tbody tr td:not(:first-child) {
    padding: 13px 12px !important;
  }

  .boxDate {
    display: flex;
    align-items: center;
    padding: 7px 0;

    > div {
      padding: 5px 10px 5px 10px;
      margin-left: 10px;
    }
  }

  .total {
    line-height: 14px;
  }
}
