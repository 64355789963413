.buttonsTab {
  margin-bottom: 20px;
}

.toggleButton {
  display: flex;

  .text {
    align-self: center;
    margin-right: 10px;
  }
}

.alertMessage {
  margin-bottom: 10px;
}
