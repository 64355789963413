@import "../../../../shared/styles/colors.scss";

.selectImage {
  position: relative;
  overflow: hidden;
  border: 1px solid $gray;
  width: 150px;
  height: 150px;
  border-radius: 3px;
}
.error {
  border: 1px solid $red;
}
.input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
}
.icon {
  position: absolute;
  right: 0px;
  bottom: 0px;
  padding: 6px 8px 5px 8px;
  color: $white;
  background: $primary;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  -moz-opacity: 0.7;
  -khtml-opacity: 0.7;
  opacity: 0.7;
  font-size: 12px;
  line-height: 12px;
  & svg {
    margin-right: 0px !important;
    color: $white !important;
  }
}

.image {
  max-width: 100%;
  height: auto;
  max-height: 200px;
  display: block;

  @media (max-width: 575px) {
    max-height: none;
  }
}
