.overview {
  padding-top: 20px;
}

.title {
  display: flex;
}

.icon {
  align-self: center;
  padding-right: 5px;
}
