.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    margin-bottom: 20px;
  }

  .subtitle {
    max-width: 340px;
    margin-bottom: 20px;
  }

  .downloadButtons {
    .badge {
      height: 44px;
      width: auto;
      &:first-of-type {
        margin-right: 5px;
      }
    }
  }

  @media (max-width: 768px) {
    .downloadButtons {
      .badge {
        width: 130px;
      }
    }
  }
}
