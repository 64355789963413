@import "../../styles/colors.scss";
.modal {
  min-width: 700px;

  @media screen and (max-width: 700px) {
    min-width: 100px;
  }
}
.divisor {
  border-top: none;
  margin: 5px 0;

  border-bottom: 1px solid $gray;
}

.row {
  margin: 20px 0px 30px 0px;
}

.buttons {
  align-self: end;
  display: flex;
  margin-bottom: 10px;
}

.badge {
  float: right;
  vertical-align: middle;
}

.gate {
  margin-top: 40px;
}
