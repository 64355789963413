.list {
  margin-bottom: 15px;
  text-align: left;
}
.item {
  margin-top: 6px;
}
.text {
  vertical-align: middle;
}
