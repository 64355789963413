.title {
  padding-bottom: 20px;
}

.sendCode {
  margin: 20px 0px;
}

.errorMessage {
  margin: 20px 6px 15px;
}

.actionButtons {
  display: flex;
}
