@import "../../../../shared/styles/colors.scss";

.redeemedBoxHeader {
  background-color: $green_dark !important;
}

.transferredBoxHeader {
  background-color: $light !important;
}

.cancelledTransferBox {
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ) !important; // overwrite style package
}

.cancelledTransferBoxHeader {
  background-color: rgba($light, 0.5) !important;
}
