@import "../../../styles/colors.scss";

.days {
  white-space: nowrap;

  button {
    cursor: pointer;
    background: $white;
    font-size: 11px;
    width: 30px;
    border: 1px solid $gray;
    text-transform: uppercase;
    border-right-width: 0;
    padding: 10px 0;

    &.error {
      border-color: $red;
    }

    &:active {
      background: $gray_light;
    }

    &.selected {
      background: $primary;
      color: $black;

      &:active {
        background: $primary;
      }
    }

    &:first-child {
      border-radius: 10px 0 0 10px;
    }

    &:last-child {
      border-right-width: 1px;
      border-radius: 0 10px 10px 0;
    }
  }

  &.small {
    button {
      width: 24px;
      padding: 4px 0;
    }
  }
  &.gutterBottom {
    margin-bottom: 10px;
  }

  &.fullWidth {
    display: flex;

    button {
      width: auto;
      flex-grow: 1;
    }
  }
}
