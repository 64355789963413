$primary: #f4b924;
$secondary: #b3b0a4;
$tertiary: #f47920;
$red: #c00000;
$red_light: #d12f4b;
$black: #000000;
$gray_darker: #44433e;
$gray_dark: #666666;
$gray: #cccccc;
$gray_light: #e7e6e4;
$gray_lighter: #f1f1f1;
$white: #ffffff;
$green_dark: #417505;
$green: #4bd763;
$green_light: #62c3a7;
$blue_darker: #3e4e60;
$blue_dark: #44586c;
$background: #dfdedc;
$light: #d2f5ed;
