.pagination {
  padding: 15px;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      padding: 0 5px;
      cursor: pointer;

      .text {
        text-decoration: underline;
        &.active {
          cursor: default;
          text-decoration: none;
        }
      }
    }
  }
}
