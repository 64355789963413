.container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.createPropertyButton {
  margin-top: 20px;
}

.sectionParkingApp {
  margin-top: 50px;
}

.downloadButtons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  a,
  img {
    width: 140px;
  }
}
