@import "../../../shared/styles/colors.scss";

.venueList {
  margin-top: 0px !important;
}

.title {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}

.addNew {
  display: flex;
  align-items: center;
  cursor: pointer;

  p,
  svg {
    transition: color 0.2s;
  }

  svg {
    font-size: 22px;
  }

  &:hover {
    p,
    svg {
      color: $gray_dark;
    }
  }
}
