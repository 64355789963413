@import "../../../../shared/styles/colors.scss";

.table {
  tr td {
    border-bottom: 1px solid $background;
  }

  tr:not(:first-child) {
    background: transparent !important;
  }

  tr.adjustment {
    td:nth-child(3),
    td:nth-child(4) {
      background: $gray_lighter;
    }
  }

  tr.checkbox {
    td:nth-child(3),
    td:nth-child(4) {
      background: $gray_lighter;
    }
  }

  tr:last-child {
    td:nth-child(3),
    td:nth-child(4) {
      background: $white;
    }
  }
}
