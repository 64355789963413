@import "../../shared/styles/mixins";

.status {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > span {
    margin-left: 5px;
  }
}

.table {
  th:first-child,
  td:first-child {
    padding-left: 16px;
  }

  th:last-child,
  td:last-child {
    padding-right: 16px;
  }
}

.alertMessage {
  margin-bottom: 10px;
}

.dictionaryGrid {
  padding-top: 5px;
}

.refundMessage {
  margin: 0 10px;
}

.dictionaryGridItem {
  @media (max-width: 640px) {
    // !important used here to override style package css
    padding: 0 8px !important;
  }
}
