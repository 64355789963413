@import "../../../../../../shared/styles/colors.scss";

.statusFailed {
  color: $tertiary;
}

.debitLabel {
  display: inline-block;
  background: $tertiary;
  color: $white;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 2px;
  margin-right: 6px;
  padding: 2px 6px 2px 6px;
}
