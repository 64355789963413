@import "../../../styles/colors.scss";

.component-vehicle-color-indicator {
  display: inline-block;
  width: 13px;
  height: 13px;
  border: 1px solid $white;
  border-radius: 100%;
  margin-right: 2px;
  vertical-align: top;

  &.beige {
    background: #e3e39c;
  }
  &.black {
    background: #000000;
  }
  &.blue {
    background: #33f;
  }
  &.brown {
    background: #933;
  }
  &.green {
    background: #090;
  }
  &.orange {
    background: #f47920;
  }
  &.red {
    background: #c00000;
  }
  &.silver_gray {
    background: #aaa;
  }
  &.purple {
    background: #9012fe;
  }
  &.white {
    background: #ffffff;
    border-color: #aaaaaa;
  }
  &.silver {
    background: #dddddd;
    border-color: #aaaaaa;
  }
  &.yellow {
    background: #f4b924;
  }
}
