.noRules {
  margin: 50px 0 30px 0;
}

.addRateBox {
  text-align: center;
  .addRateButton {
    margin: 20px 0;
  }
}

.versionInfo {
  margin-bottom: 5px;

  .versionInfoBagde {
    display: inline-block;
    margin-left: 10px;
  }
}
