@import "../../shared/styles/colors.scss";

.transactionData {
  border-top: 1px solid $gray_darker;
  margin-top: 30px;
  padding-top: 30px;

  > h5 {
    padding-left: 4px;
    padding-bottom: 6px;
    margin-bottom: 5px;
  }
}
