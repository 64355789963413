@import "../../../../shared/styles/colors.scss";

.title {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}

.addNew {
  display: flex;
  align-items: center;
  cursor: pointer;

  p,
  svg {
    transition: color 0.2s;
  }

  svg {
    font-size: 22px;
  }

  &:hover {
    p,
    svg {
      color: $gray_dark;
    }
  }
}

.noLots {
  border-top: 5px solid $gray_darker;
  margin-top: 5px;
  padding-top: 14px;
}

.lotList {
  margin-top: 0px !important;
}
