@import "../../../../../../../shared/styles/colors";

.dayInfo {
  margin-top: 10px;
}

.rateType {
  display: flex;
  flex-direction: column;
}

.rateValue {
  align-items: center;
}

.editableRateComponent {
  display: block;
  text-align: left;
  width: 100%;
  align-self: flex-start;
}

.earlyBirdColumn {
  border-left: 1px solid $gray;
}

.rateValueField {
  height: 47px !important;
  width: 125px !important;
  margin-right: 10px;
  font-size: 120% !important;
}

.editActions {
  text-align: center;
}

.editActionsButton {
  margin: 0 5px;
  cursor: pointer;
}

.summary {
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
}
