.tab {
  margin-bottom: 40px;
  display: none;

  @media (min-width: 640px) {
    display: block;
  }
}

.boxLoading {
  padding: 30px 0;
}

.boxTables {
  flex-direction: column;
  margin: 10px 0;

  table {
    margin-bottom: 10px;
    margin: 0 20px 10px 20px;
  }
}

.boxLegend {
  display: flex;
  align-items: center;
}

.circle {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  margin-right: 6px;

  &.purchased {
    background: #e2e2e2;
  }
  &.parked,
  &.redeemedParked {
    background: #0091b7;
  }
  &.cancelled {
    background: #f47920;
  }
  &.expired,
  &.redeemedExpired {
    background: #44433e;
  }
  &.redeemed,
  &.transfersRedeemed {
    background: #417505;
  }
  &.pendingTransfers,
  &.transferred {
    background: #62ccb6;
  }
}
