@import "../../../shared/styles/colors.scss";

.formContainer {
  max-width: 450px;
  margin: auto;

  .privacyPolicyUrl a {
    color: $tertiary;
    text-decoration: none;
  }
  .footer {
    display: flex;
    padding: 20px 0;
  }
}
