.sentryRef {
  height: 45px;
}

.searchAndCSV {
  display: flex;

  .csv {
    margin-left: 10px;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 20px;

    .csv {
      display: none;
    }
  }
}

.badge {
  margin-left: 5px;
}
