.wrapper {
  margin-bottom: 20px;
}

.groupTitleContainer {
  display: flex;
}

.groupTitle {
  flex: 1;
}

.splitGroupLink {
  align-self: end;
}
