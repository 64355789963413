.noPasses {
  margin-top: 15px;
}

.searchAndCSV {
  display: flex;
  height: 100%;
  align-items: flex-end;

  .csv {
    margin-left: 10px;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
    display: block;
    height: auto;

    .csv {
      display: none;
    }
  }
}
