@import "../../../styles/mixins";
@import "../../../styles/colors.scss";

ul.dictionary {
  li {
    border-top: 1px solid $gray;
    padding: 10px 0;

    &:after {
      @include clearfix;
    }

    &:first-child {
      border-top-width: 0;
    }

    .label {
      float: left;
      font-weight: 600;
    }

    .value {
      float: right;
      text-align: right;

      &.multiline {
        line-height: 140%;
      }
    }
  }

  &--with-top-line {
    li:first-child {
      border-top-width: 1px;
    }
  }
}
