@import "../../shared/styles/colors.scss";

.myAccount {
  .content {
    max-width: 460px;
    margin-top: 40px;

    input {
      margin-bottom: 20px;
    }

    button p {
      text-align: center;
    }
  }

  .backLink {
    display: flex;
    align-items: center;

    &:hover {
      svg,
      p {
        color: $secondary;
      }
    }
  }
}
