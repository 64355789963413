@import "../../shared/styles/colors.scss";

.backLink {
  display: flex;
  align-items: center;

  &:hover {
    svg,
    p {
      color: $secondary;
    }
  }
}

.dateTime {
  position: relative;

  label {
    font-size: 16px !important;
    font-weight: 700 !important;
  }

  p {
    position: absolute;
    bottom: -10px;
  }
}

.radio {
  padding-right: 10px;
}

.col {
  display: flex;
}

.toggle {
  display: flex;
}
