.list {
  li {
    min-height: 41px;
  }
  .alignRight {
    text-align: right;
  }
}

.paymentNotFinished {
  padding: 0 15px 15px 15px;
}

.transactionHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 8px;
}

.breakDownTable {
  tr td:last-of-type {
    text-align: right;
  }
}

.guestBadge {
  margin-left: 5px;
}
