// .horizontalScrollable {
//   display: flex;
//   gap: 5px;
// }
.wrapper {
  flex-grow: 1;
  overflow-x: hidden;
}

.scrollContainer {
  flex-shrink: 0;
}

.buttonContainer {
  flex-shrink: 0;
}

.disabled {
  opacity: 0.2;
}

.arrow {
  background-color: transparent;
  border: none;
  cursor: pointer;
  animation: arrowAnimation 0.8s infinite alternate ease-in-out;
}
