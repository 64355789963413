@import "../../../../shared/styles/colors.scss";

.spaces {
  margin-bottom: 15px;
}

.breakdownTitle {
  margin-top: 5px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid;
}

.legendColor {
  display: flex;
  margin-bottom: 8px;

  &:before {
    content: "";
    width: 23px;
    height: 23px;
    margin-right: 5px;
    display: inline-block;
  }

  &.space:before {
    background-color: #e2e2e2;
  }
  &.ondemand:before {
    background-color: #f4b924;
  }
  &.permit:before {
    background-color: #a15c7f;
  }
  &.reservedparking:before {
    background-color: #62c3a7;
  }
  &.monthly:before {
    background-color: #f47920;
  }
  &.purchased:before {
    background-color: #0098b7;
  }
  &.parked:before {
    background-color: #305ea0;
  }
}

.chartsColumn {
  border-left: 1px solid $gray;
}

/* PIE CHARTS */
.chartBox {
  padding-bottom: 15px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .chart {
    position: relative;

    .percent {
      background: white;
      width: 56px;
      height: 56px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -29px;
      margin-top: -29px;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
      cursor: pointer;
    }

    .percent,
    .breakdown {
      user-select: none;
    }

    circle.ondemand,
    circle.reservedparking,
    circle.permit,
    circle.monthly,
    circle.purchased,
    circle.parked {
      fill: transparent;
      stroke-width: 32;
    }

    circle.ondemand {
      stroke: #edb944;
    }

    circle.permit {
      stroke: #a15c7f;
    }

    circle.reservedparking {
      stroke: #62c3a7;
    }

    circle.monthly {
      stroke: #f47920;
    }

    circle.purchased {
      stroke: #0098b7;
    }

    circle.parked {
      stroke: #305ea0;
    }

    svg {
      width: 120px;
      height: 120px;
      transform: rotate(-90deg);
      background: #e2e2e2;
      border-radius: 50%;

      circle {
        transition: stroke-dasharray 1s;
      }
    }
  }
}
