@import "../../../styles/colors.scss";

.navWrap {
  padding: 30px 0 0 0;

  a {
    color: $gray_darker;
    text-decoration: none;
  }

  a:visited {
    color: $gray_darker;
  }

  a:hover,
  a:focus {
    color: $gray_darker;
    text-decoration: none;
  }

  a:active {
    color: $gray_darker;
  }

  .logo {
    float: left;
  }

  .back {
    float: right;
    position: relative;
    top: 27px;
  }

  .nav {
    flex: 1;
    border-bottom: 1px solid $gray_darker;
    overflow: visible;
    height: 100%;

    .links,
    .account {
      display: block;
    }

    .links {
      float: left;
      text-align: left;
      margin-bottom: -8px;

      li {
        display: inline-block;
        margin-bottom: 8px;
        margin-right: 30px;
        padding-bottom: 8px;
        border-bottom: 5px solid transparent;

        a {
          display: block;
        }
      }

      li:last-of-type {
        margin-right: 0px;
      }

      li:hover {
        border-bottom-color: $secondary;
      }

      .active {
        border-bottom-color: $gray_darker !important;
        font-weight: bold;
      }
    }

    .account {
      float: right;
      text-align: right;
      margin-bottom: -8px;

      li {
        display: inline-block;
        margin-bottom: 8px;
        margin-left: 30px;
        padding-bottom: 8px;
        border-bottom: 5px solid transparent;

        a {
          display: block;
          font-size: 14px;

          i {
            font-size: 15px;
          }
        }
      }

      li:first-of-type {
        margin-left: 0px;
      }

      li:hover {
        border-bottom: 5px solid $secondary;
      }

      .active {
        border-bottom: 5px solid $gray_darker !important;
      }
    }
  }

  @media screen and (max-width: 40em) {
    padding: 0px;
    border: 0px;

    > div {
      padding: 0 !important;
    }

    &.openMenu {
      .nav {
        display: block;
      }
    }

    .nav {
      display: none;
      border-bottom: 0;
      margin: 0 !important;
      padding: 0 !important;

      .links,
      .account {
        display: block;
        float: none;
        width: 100%;
        text-align: left;
        padding: 0;
        margin: 0;
        background: $secondary;

        li {
          float: none;
          display: block;
          width: 100%;
          position: relative;
          margin: 0;
          padding: 0;
          border: 0;

          a {
            display: block;
            padding: 15px;
          }

          a:hover {
            background: $gray_dark;
            color: $white;
          }
        }
      }

      .account {
        li {
          border: 0px !important;
        }
      }
    }

    .back {
      display: none;
    }
  }
}
