@import "../../../../shared/styles/colors.scss";
.transparent {
  background-color: transparent !important; // override citifyd/style
}

.divisor {
  border: 0;
  border-bottom: 1px solid $gray;
  margin: 12px 0;
}
