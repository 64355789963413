.tableRef {
  height: 45px;

  > div > div > div {
    margin-top: 20px !important;
  }
}

.textLink {
  cursor: pointer;
}

.table {
  width: 2500px;
}
