.table {
  margin-top: 15px;
  margin-left: -15px;
  width: calc(100% + 30px);

  .checkbox {
    margin-top: 8px;
  }
}

.button {
  width: 30px;
  height: 30px;
  padding: 0 !important;
  &.addRateButton {
    margin-top: 10px;
    margin-left: -5px;
  }
}
