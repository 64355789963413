.or {
  display: flex;
  align-items: flex-end;
  margin-bottom: 8px;

  @media screen and (max-width: 767px) {
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
