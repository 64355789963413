.pageHeader {
  display: none;

  @media (min-width: 640px) {
    display: flex;
  }
}

.searchMobile {
  display: flex;
  margin: 0 0 20px 0;

  @media (min-width: 768px) {
    display: none;
  }
}

.searchDesktop {
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}
