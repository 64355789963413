.noGates {
  display: inline-flex;
}

.lot {
  margin-top: 30px;
}

.unredeemedLink {
  margin-top: 10px;
  cursor: pointer;
  display: inline-block;
}
