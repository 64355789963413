.downloadPdfReceipt {
  cursor: pointer;
}

.ticketHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 8px;
}

.payment {
  display: flex;
}

.table {
  padding-top: 10px;
}
