@import "../../../../shared/styles/colors.scss";

.actions {
  display: flex;
  align-items: center;
  gap: 15px;
}

.username {
  display: flex;
}

.status {
  display: flex;
  flex-direction: "column";
  align-items: center;
  gap: 8px;
  margin-left: 8px;

  @media (max-width: 720px) {
    p {
      display: none;
    }
  }
}

.toggle {
  input:not(:checked) + i {
    background: $red_light;
  }
}

.badge {
  margin-left: 4px;
}

.list li {
  min-height: 43px;
}

.icon {
  margin: 1px 0 0 5px;
}
.link {
  margin-left: 5px;
}
