@import "../../../../styles/colors.scss";

.container {
  background: $gray_lighter;
  padding: 10px;
  margin: 15px 0px;

  .verificationCodeTitle {
    margin-bottom: 5px;
  }

  .tryAgain {
    margin-top: 10px;

    button {
      margin: 5px 0;
    }
  }
}
