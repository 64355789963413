.smallImageGroup {
  display: flex;
}

.smallImage {
  margin-left: 20px !important;
  margin-bottom: 10px;
}

.text {
  padding-left: 20px;
}
