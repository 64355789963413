@import "../../../shared/styles/colors.scss";

.divisor {
  margin: 10px 0px;
  border-bottom: 1px solid $gray;
}

.loadingContainer {
  position: relative;
}

.loading {
  //fixed size of a table with 10 items
  //so that the next and previous buttons do not move on the
  //screen while data is being loaded
  min-height: 450px;
}
