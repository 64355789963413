.tableRef {
  height: 45px;
  margin-bottom: 30px;
  margin-top: 40px;
}

.columnTitle {
  display: flex;

  &.flexEnd {
    justify-content: flex-end;
  }
}
