@import "../../../../shared/styles/colors.scss";

.fullWidth {
  width: 100%;
  text-align: center;
}

.redeemed {
  background-color: $green_dark !important;
  color: $white;
}

.transferred {
  background-color: $light !important;
  color: $gray_darker;
}
