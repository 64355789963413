@import "../../../../shared/styles/colors.scss";

.boxContent {
  margin-top: 25px;

  > div:nth-child(1) {
    transition: all 250ms ease;
    margin-bottom: 0;
  }

  &.noDevices {
    padding-bottom: 0;

    > div:nth-child(1) {
      border-radius: 10px;
    }
  }

  .toggleButton {
    i {
      margin-right: 0;
    }
  }

  .icon {
    cursor: pointer;

    &:hover {
      color: $tertiary;
    }
  }

  .table {
    th,
    td {
      font-size: 13px;
    }

    tr td:first-child {
      white-space: pre-line;
      padding-left: 14px;

      @media (max-width: 768px) {
        white-space: pre;
      }
    }

    tr th:first-child {
      padding-left: 14px;
    }
  }

  .boxTable {
    overflow: hidden;
    transition: all 250ms ease;

    &.hide {
      max-height: 0 !important;
    }
  }
}
