.selectReport {
  display: flex;
  width: 400px;

  .text {
    align-self: center;
    padding-right: 5px;
  }
}

.divisor {
  border-bottom: 1px solid #44433e;
  margin-top: 20px;
  margin-bottom: 50px;
}
