.fieldWithIcon {
  display: flex;

  .icon {
    margin-right: 15px;
    margin-top: 8px;
    width: 22px;
  }
}

.checkbox {
  padding-top: 8px;
}

.row {
  margin: 10px 0;
}

.desktopSeparator,
.mobileSeparator {
  text-align: center;
}

.mobileSeparator {
  margin: 5px 0;
}

.fieldBox {
  display: flex;
  align-items: flex-start;
}

.desktopSeparator {
  display: none;
  margin: 10px 5px 0 5px;
}

@media (min-width: 768px) {
  .mobileSeparator {
    display: none;
  }

  .desktopSeparator {
    display: block;
  }
}

.table {
  margin-top: 15px;
  margin-left: -15px;
  width: calc(100% + 30px);
}
