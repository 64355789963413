.buttons {
  display: flex;
}

.activeCard {
  .sucessMessage {
    border-radius: 10px 10px 0 0;
  }

  .payment {
    padding: 20px 10px;
  }

  .assignedTo {
    padding-top: 20px;
  }

  .footer {
    padding: 0 10px 10px !important;
  }
}
