.title {
  margin: 20px 0 15px 0;
}

.total {
  line-height: 14px;
}

.table {
  tr td,
  tr th {
    padding: 13px 12px !important;
  }
}
