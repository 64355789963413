@import "../../../styles/colors.scss";

.stickyFooter {
  background: $gray_darker;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  padding: 10px 0;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  display: block;
  box-sizing: border-box;
  color: $white;

  .column {
    display: flex;
    &.space-between {
      justify-content: space-between;
    }

    &.flex-start {
      justify-content: flex-start;
    }

    &.flex-end {
      justify-content: flex-end;
    }

    &.space-around {
      justify-content: space-around;
    }
  }

  // same breakpoint we use for Grid mobile on the @citifyd/style
  @media screen and (max-width: 40em) {
    .desktop {
      display: none;
    }
  }

  @media screen and (max-width: 1170px) {
    height: 63px;
  }

  &__content {
    position: relative;
    max-width: 1110px;
    margin: 0 auto;

    .message {
      margin-top: 14px;
      float: left;
    }

    @media (max-width: 660px) {
      &.mobile {
        display: flex;
        margin: 0px 85px 0 0 !important;

        button {
          margin: 0 3px !important;
          flex: 1;
          width: auto;
          right: auto;
          position: relative;
        }

        button:first-of-type {
          margin-left: 3px;
        }

        button:last-of-type {
          margin-right: 3px;
        }
      }
    }
  }

  .blockOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparentize($gray_darker, 0.2);
    z-index: 1;
  }

  a,
  a:visited {
    color: $white;

    &:hover {
      color: $secondary;
    }
  }

  .back {
    margin-top: 14px;
    i {
      margin-right: 3px;
    }
    a {
      text-decoration: none;
    }
  }

  .save {
    margin-left: 10px;
  }

  button:disabled {
    cursor: default !important;
    opacity: 0.5;
  }
}
