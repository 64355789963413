@import "../../shared/styles/colors.scss";

.container {
  position: relative;
  min-height: 100px;
}

.divisor {
  border-bottom: 1px solid $gray;
  margin: 30px 0 20px;
}

.text {
  vertical-align: inherit;
}
