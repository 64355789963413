.button {
  width: 30px;
  height: 30px;
  padding: 0 !important;

  &.addRateButton {
    margin-top: 10px;
    margin-left: -5px;
  }
}

.headerTitle {
  display: flex;
}

.toggleButton {
  align-self: center;
  padding-right: 5px;
}

.text {
  display: flex;

  .icon {
    margin-left: 8px;
    align-self: center;
  }
}

.tooltip {
  width: 400px;
}

.boxContent {
  margin-bottom: 0px !important;
}

.boxContentBorder {
  border-radius: 5px !important;
}

.noBorderRadius {
  border-radius: 5px 5px 0px 0px !important;
}

.checkbox {
  align-self: center;
}
