@import "../../../../shared/styles/colors.scss";

.chartCeption {
  width: 240px;
  display: block;
  margin: 0 auto;
  padding-top: 20px;
  position: relative;

  > svg {
    width: 240px;
    height: 240px;
    transform: rotate(-90deg);
    background: #f1f1f1;
    border-radius: 50%;

    circle {
      transition: stroke-dasharray 1s;
    }
  }

  .breakdown:before {
    content: "▴";
    display: block;
    position: absolute;
    left: 112px;
    top: -20px;
    font-size: 30px;
    color: $white;
    background: transparent;
    text-shadow: 0px -2px 2px rgba(69, 68, 63, 0.3);
  }

  .smallChart {
    background: $white;
    width: 120px;
    height: 120px;
    position: absolute;
    top: 81px;
    left: 60px;
    border-radius: 70px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);

    > svg {
      width: 110px;
      height: 110px;
      transform: rotate(-90deg);
      background: #f1f1f1;
      border-radius: 50%;
      position: relative;
      top: 5px;
      left: 5px;

      circle {
        transition: stroke-dasharray 1s;
      }
    }
  }

  .smallChart {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  circle {
    fill: transparent;
    stroke-width: 32;
  }

  circle.purchased {
    stroke: #e2e2e2;
  }
  circle.parked {
    stroke: #0091b7;
  }
  circle.cancelled {
    stroke: #f47920;
  }
  circle.expired {
    stroke: #44433e;
  }
  circle.redeemed {
    stroke: #417505;
  }
  circle.transfers {
    stroke: #62ccb6;
  }
}
