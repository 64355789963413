@import "../../../shared/styles/colors.scss";
.divisor {
  border-bottom: 1px solid $gray;
}
.agent {
  border-radius: 10px;
  box-shadow: 2px 2px 5px 2px rgba($black, 0.2);
  padding-bottom: 2px;
  margin-bottom: 20px;
}

.searchContainer {
  display: flex;
}

.search {
  margin-right: 20px;
}

.container {
  padding: 20px 0;
}
