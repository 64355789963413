@import "../../../../shared/styles/colors";

.actionButtons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  @media (max-width: 767px) {
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    svg {
      margin: 0 10px;
    }
  }
}
.button {
  text-align: center;
}
.rateAndBillingCycle {
  display: flex;
}

.rate {
  width: 234px;
  margin-right: 10px;
}

.billingCycle {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}

.border {
  border-bottom: 1px solid $gray;
  padding: 20px 0px;
}
.disabled .text {
  color: $secondary !important;
}
