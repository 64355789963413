.badge {
  width: 100%;
  text-align: center;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.1s linear;

  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }

  span {
    font-size: 13px !important;
    color: black;
  }
}

.animate {
  margin-left: -8px;
  margin-right: -8px;
  padding: 8px;
}
