.accountType {
  margin-bottom: 15px;
}
.radio {
  margin-right: 10px;
}
.tooltip {
  max-width: 300px;
  line-height: 125%;
  text-align: center;
}
.tooltipLabel {
  display: inline;
}
.gutterBottom {
  margin-bottom: 10px;
}
