.wrapper {
  margin-top: 12px;
  width: 100%;
}

.table-head-cel {
  align-items: center;
  display: flex;
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  height: 36px;
}

.table-body-cel {
  align-items: center;
  display: flex;
  flex: 1;
  font-size: 14px;
  height: 48px;

  strong {
    font-size: 16px;
    font-weight: 600;
  }
}
