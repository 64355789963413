@import "../../shared/styles/colors.scss";

.referralCodes {
  .pageHeader {
    margin-bottom: 10px;
  }

  .addNew {
    float: right;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 35px;

    p,
    svg {
      transition: color 0.2s;
    }

    svg {
      font-size: 22px;
    }

    &:hover {
      p,
      svg {
        color: $gray_dark;
      }
    }
  }

  .link {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  .table {
    tr td,
    tr th {
      padding: 13px 12px !important;
    }
  }
}
