.revenueContent {
  margin-bottom: 20px;
}

.mainTable {
  td {
    vertical-align: middle !important;
  }
}

.smallTable {
  th {
    padding-top: 0 !important;
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
