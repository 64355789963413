@import "../../../../shared/styles/colors";

.backLink {
  display: flex;
  align-items: center;
}

.step2 {
  .advancedRateText {
    margin-bottom: 15px;
  }

  .box {
    padding: 0 !important;
    .boxContent {
      padding: 0 !important;
    }
    .boxHeader {
      margin-bottom: 0 !important;

      .toggleButton {
        display: flex;

        .text {
          align-self: center;
          margin-right: 10px;
        }
      }
    }
  }

  .pricingWrapper {
    .smallColumn {
      width: 12.5%;
      padding: 15px;
      margin-top: 0;
      box-sizing: border-box;
    }

    .largeColumn {
      width: 62.5%;
      padding-right: 0px;
      padding: 20px;
      text-align: center;
      margin-top: 0;
      box-sizing: border-box;
    }

    .mediumColumn {
      width: 25%;
      padding-right: 0px;
      padding: 20px;
      text-align: center;
      margin-top: 0;
      box-sizing: border-box;
      margin-top: 0;
    }

    .days {
      text-align: center;
      background: $white;

      &.mobile {
        display: none;
      }

      .smallColumn {
        border-left: 1px solid $gray_lighter;

        &:first-of-type {
          text-transform: none;
          border-left: 0px;
        }
      }
    }

    .sun,
    .moon {
      text-align: center;
      flex: 1;
      min-height: 200px;
    }

    .sun {
      background: $primary;
    }

    .moon {
      background: $blue_darker;
      border-bottom-left-radius: 5px;
    }

    .days,
    .weekday,
    .weeknight {
      display: flex;
    }

    .weekday,
    .weeknight {
      .largeColumn,
      .mediumColumn,
      .smallColumn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid $gray;
        border-bottom: 1px solid $gray;
      }
    }

    .weekday {
      .largeColumn {
        align-items: center;
        background: $gray_lighter;
      }

      .mediumColumn {
        background: $gray_lighter;
      }
    }

    .weeknight {
      .largeColumn {
        background: $gray_light;
        align-items: center;
      }

      .mediumColumn {
        background: $gray_light;
        border-bottom-right-radius: 5px;
      }
    }

    .actions {
      border-top: 0px;

      a {
        font-size: 20px;
        padding: 9px 11px;
        border: 1px solid $gray_dark;
        color: $gray_dark;
        border-radius: 30px;
        width: 39px;
        height: 39px;

        &:active {
          background: rgba(0, 0, 0, 0.1);
        }
      }

      a:first-of-type {
        padding: 9px;
        margin-right: 5px;
      }
    }

    .options {
      float: right;
      width: 60.2%;
      padding: 0px 20px;
      box-sizing: border-box;

      .checkbox {
        p {
          text-align: left !important;
        }
      }
    }
  }

  .alertMessage {
    margin-bottom: 10px;
  }

  @media handheld, only screen and (max-width: 767px) {
    .pricingWrapper {
      .actions {
        margin-top: 15px !important;
        padding-top: 0px !important;
        text-align: center !important;
      }

      .days {
        &.desktop {
          display: none;
        }

        &.mobile {
          display: block;
          text-align: center;
          text-transform: uppercase;
          padding: 15px;

          ul {
            display: block;
            padding: 10px 0px 5px 0px;

            li {
              display: inline-block;
              border: 1px solid $secondary;
              border-radius: 3px;
              margin: 0 1px;

              a {
                background: $gray;
                padding: 7px 5px;
                min-width: 30px;
                max-width: 40px;
                text-decoration: none;
                height: 30px;
                display: block;
                margin: 1px;

                &.active {
                  background: $primary;
                }
              }
            }
          }
        }
      }

      .weekday,
      .weeknight {
        clear: both !important;
        display: block !important;

        .smallColumn,
        .largeColumn,
        .mediumColumn {
          display: inline-table !important;
          width: 100% !important;
          clear: both;
          float: none;
          padding: 30px 20px 20px 20px;

          .nums,
          .options {
            width: 100%;
            display: block;
            border: 0px;
            padding: 0px;
          }

          .notify {
            margin-top: 20px;
          }
        }

        .moon,
        .sun,
        .largeColumn {
          border-right: 1px solid $gray_lighter;
        }

        .sun,
        .moon {
          min-height: auto;
        }

        .sun {
          border-top: 1px solid $gray_lighter;
        }

        .moon {
          border-radius: 0px;
        }
      }

      .weeknight {
        .smallColumn {
          border-bottom-left-radius: 5px;
        }
      }
    }
  }
}
