@import "../../../../shared/styles/colors.scss";

.table {
  tr td {
    border-bottom: 1px solid $background;
  }

  tr:nth-child(3),
  tr:nth-child(4) {
    background: transparent !important;

    td:nth-child(4),
    td:nth-child(5) {
      background: $white;
    }
  }
}
