.container {
  display: flex;
}

.tooltip {
  width: 400px;
}

.shortField {
  width: 100px !important;
}

.textarea {
  width: 500px;
  margin-left: 30px;
}

.enabled {
  align-self: center;
  padding-right: 5px;
}

.customMessageContainer {
  max-width: 500px;
  padding-top: 10px;
}

.undoButton {
  margin-top: 10px;
}

.tooltipIcon {
  margin-top: 3px;
}

.editButton {
  flex: 1;
  align-items: flex-start;
}
