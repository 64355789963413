.detail {
  padding-top: 8px;

  ul,
  h4,
  address {
    line-height: 150%;
  }

  h4 {
    padding-bottom: 0;
  }

  address {
    display: block;
    font-style: normal;
    margin-bottom: 8px;
  }

  .lotActions {
    margin-top: 10px;
  }

  .disapproveButton {
    background-color: aqua;
  }
}
