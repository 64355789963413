@import "../../../../../shared/styles/colors";

.gateTitle {
  width: 100% !important;
  max-width: 100% !important;
  padding: 0 !important;
}

.systemGateHeader {
  display: flex;
  height: 62px;
  align-items: center;
  justify-content: space-between;
  border: 0 solid $gray;
  border-width: 2px 0;
  margin: 20px 0 10px;
  padding: 10px 0;
}
