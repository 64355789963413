@import "../../../../shared/styles/colors.scss";
.divisor {
  border-bottom: 1px solid $gray_darker;
  margin: 20px 0;
}
.alertMessage {
  margin-bottom: 20px;
}

.lotName {
  margin-bottom: 30px;
}

.summaryTable {
  padding-bottom: 30px;
}
