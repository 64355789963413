.tab {
  display: flex;
  flex-direction: row;

  @media screen and (min-width: 768px) {
    flex-direction: column;
  }
}

.small,
.big {
  font-weight: 700;
  font-size: 13px !important;
}

.big {
  @media screen and (min-width: 768px) {
    font-size: 16px !important;
    font-weight: 600;
  }
}

.small {
  margin-left: 5px;

  @media screen and (min-width: 768px) {
    font-weight: 300;
    font-size: 11px !important;
    margin-left: 0;
  }
}
