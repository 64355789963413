.container {
  display: flex;
}

.tooltip {
  padding-left: 5px;
}

.text {
  width: 400px;
}

.icon {
  margin-top: 5px;
}
