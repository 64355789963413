.select {
  margin-bottom: 15px;
  margin-top: 5px;

  @media screen and (min-width: 767px) {
    display: none;
  }
}

.divisor {
  @media screen and (min-width: 767px) {
    display: none;
  }
}
