.tableBox {
  display: flex;
  overflow: hidden;
}

.ratesTable {
  width: 220px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.arrowIcon {
  position: absolute;
  right: 0;
  top: 50%;
  font-size: 31px;
  cursor: pointer;
  animation: arrowAnimation 0.8s infinite alternate ease-in-out;

  &.hideArrow {
    animation: arrowHidden 0.6s forwards;
  }
}

.rateBoard {
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  &:before {
    left: 0;
    width: 11px;
    background: linear-gradient(
      90deg,
      #00000036 30%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

@keyframes arrowHidden {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 0;
  }
}

@keyframes arrowAnimation {
  0% {
    -webkit-transform: translateX(0);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: translateX(-0.2em);
    opacity: 0.9;
  }
}
