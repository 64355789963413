@import "../../../styles/mixins";

.dictionary {
  flex: 1;
  font-size: 14px;

  .item {
    min-height: 43px;
    line-height: 24px;
    padding-bottom: 10px !important;

    &:first-child {
      border-top-width: 0;
    }

    .label {
      padding-right: 5px;
    }

    .value {
      text-align: right;

      &.multiline {
        line-height: 140%;
      }
    }
  }

  &.withTopLine {
    li:first-child {
      border-top-width: 1px;
    }
  }
}
