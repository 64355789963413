.boxContent {
  padding-bottom: 50px;
}

.refundReason {
  padding-bottom: 30px;
  border-bottom: 1px solid;
  margin-bottom: 30px;
}

.checkboxes {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  align-items: flex-start;
}

.transactionHistoryTitle {
  padding-bottom: 5px;
}
