@import "../../../styles/colors.scss";

.topNotificationBar {
  background: $primary;
  width: 100%;
  font-size: 14px;
  text-align: left;
  box-sizing: border-box;
  border-bottom: 1px solid $gray_darker;
  padding: 16px 0px;

  .text {
    vertical-align: middle;
    line-height: 150%;
    width: 100%;

    a {
      color: $black;
    }

    &.centered {
      text-align: center;
    }
  }

  .textWithButtons {
    display: table;

    .text {
      display: table-cell;
      width: 99%;
    }

    .buttons {
      vertical-align: top;
      display: table-cell;
      white-space: nowrap;
      padding-left: 10px;
    }
  }
}
