@import "../shared/styles/colors.scss";

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body,
html {
  background: $background;
  color: $gray_darker;
  font-size: 16px;
  font-weight: normal;
  height: 100%;
  margin: 0px;
}

button {
  line-height: 120%;
}

strong,
b {
  font-weight: bold;
}

a {
  color: $gray_darker;
  text-decoration: underline;
}

a:hover,
a:focus {
  color: $gray_darker;
  text-decoration: none;
  cursor: pointer;
}

a:active {
  color: $gray_darker;
}

img {
  width: 100%;
  height: auto;
}

/* Content */
.site-wrapper {
  min-height: 100%;
  position: relative;
}

.site-content {
  padding-bottom: 120px;
  padding-top: 25px;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;

  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@media print {
  body {
    background: $white;
  }

  .nav-wrap,
  .menu-toggle {
    display: none;
  }
}
