.table {
  margin-top: 10px;
  margin-left: -15px;
  width: calc(100% + 30px);

  th {
    &:nth-of-type(4),
    &:nth-of-type(5),
    &:nth-of-type(6),
    &:nth-of-type(7) {
      padding-left: 3px !important;
      padding-right: 3px !important;
    }
    &:nth-of-type(8) {
      padding-left: 3px !important;
    }
  }

  .checkbox {
    margin-top: 8px;
  }
}
