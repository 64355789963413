@import "../../../styles/colors.scss";

.emptyAppend {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;

  .wereSorry,
  .inMind {
    width: 49%;
    padding: 8px;
    text-align: center;
    background: $white;
    border-radius: 5px;
    box-shadow: 2px 2px 3px $gray;

    h2 {
      padding-bottom: 15px;
    }

    .inner {
      padding: 40px 60px 55px 60px;
      height: 100%;
    }

    .btn {
      font-size: 13px;
      line-height: 13px;
      letter-spacing: 1px;
      text-decoration: none;
      position: relative;
      top: 20px;
      border-radius: 5px;
      padding: 15px;
      width: 100%;
      max-width: 250px;
      display: inline-block;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;

    .wereSorry,
    .inMind {
      width: 100%;

      .inner {
        padding: 40px 40px 55px 40px;
      }
    }

    .inMind {
      margin-top: 10px;
    }
  }

  .wereSorry {
    .inner {
      background: $primary;
    }

    .btn {
      background: $gray_darker;
      color: $white;
    }

    .btn:focus {
      background: $gray_dark;
    }
  }

  .inMind {
    .inner {
      background: $gray_darker;
      color: $white;
    }

    .btn {
      background: $primary;
      color: $gray_darker;
    }
  }
}
