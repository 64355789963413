.marker {
  transform: translate(-50%, -50%);
  width: 30px;
}

.marker img {
  width: 30px;
}

.container {
  border: 1px solid #ccc;
  width: 100%;
  height: 180px;
}
