.exportButtons {
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  > a {
    margin-left: 20px;
  }
  @media screen and (max-width: 767px) {
    margin-top: 5px;
  }
}

.select {
  label {
    font-weight: 400 !important;
    font-size: 15px !important;
    margin-bottom: 8px;
    line-height: 15px;
  }
}
