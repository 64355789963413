@import "../../shared/styles/colors.scss";

.content {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 2px 2px 5px 2px rgba(69, 68, 63, 0.3);
  background: $white;
}

.icon {
  margin-left: 16px;
  cursor: pointer;

  &:hover {
    color: $gray_light;
  }
}
