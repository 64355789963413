.link {
  display: inline-flex;
  text-decoration: none;
  align-items: center;
}

.icon {
  margin-right: 10px;
}

@media print {
  .icon {
    display: none;
  }
}
