@import "../../../../shared/styles/colors";

.pageHeader {
  margin-bottom: 20px;
  align-items: center;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
    align-items: normal !important;
  }

  &.bordered {
    border-bottom: 3px solid $gray_darker;
    padding-bottom: 10px;
  }

  .pageHeaderActions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .pageHeaderTitle {
    display: flex;
    align-items: flex-end;
  }

  .pageHeaderAction {
    margin-left: 15px;

    @media only screen and (max-width: 575px) {
      margin-left: 5px;
      margin-right: 5px;
      flex: auto;
      margin-top: 20px;
      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  .icon {
    margin-right: 10px;

    @media only screen and (max-width: 575px) {
      display: none;
    }
  }
}

.button {
  display: flex;
  align-items: center;
  cursor: pointer;
}
