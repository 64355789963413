@import "../../shared/styles/colors.scss";
.container {
  max-width: 450px;
  margin: auto;

  .footer {
    text-align: center;
    padding-top: 20px;
  }
}

.iframeContainer {
  background-color: $blue_dark;
  max-width: 600px;
  margin: auto;
  border-radius: 20px;
  border: 10px solid $white;

  .iframeContent {
    padding: 40px 20px;
    background-color: $blue_dark;
    border-radius: 20px;
  }

  .iframe {
    border: 0px;
  }
  .footer {
    padding-top: 30px;
    display: flex;
    max-width: 350px;
    margin: auto;
  }
}
