@import "../../../shared/styles/colors.scss";

.dateTime {
  display: flex;
}

.backLink {
  display: flex;
  align-items: center;

  &:hover {
    svg,
    p {
      color: $secondary;
    }
  }
}

.checkbox {
  margin-top: 33px;
}
