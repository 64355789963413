.column {
  margin-bottom: 15px;
}

.licensePlateColumn {
  display: flex;
  align-items: flex-end;
}

.similarLicensePlate {
  margin-bottom: 8px !important;
  margin-left: 10px !important;
}

.boxCSV {
  margin-top: 27px;
  display: flex;
  justify-content: flex-end;
  max-width: 60px;
  margin-bottom: 15px;
}
