@import "../../../shared/styles/colors.scss";

.venueItem {
  margin-bottom: 15px;
  width: 100%;

  .editLink {
    font-size: 24px;

    &:hover svg {
      color: $gray_light;
    }
  }

  .deleteLink {
    margin-left: 16px;

    &:hover {
      color: $gray_light;
      cursor: pointer;
    }
  }

  .loadingIcon {
    margin-left: 16px;
    animation: loadingAnimation 2s linear infinite;
  }

  @keyframes loadingAnimation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .address {
    line-height: 24px;
  }
}
