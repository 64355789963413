@import "../../../../shared/styles/colors.scss";

.selectImage {
  position: relative;
  overflow: hidden;
  border: 1px solid $gray;
  width: 150px;
  height: 150px;
  border-radius: 3px;
}

.small {
  width: 70px;
  height: 70px;
}

.error {
  border: 1px solid $red;
}
.input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 0;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
}
.icon {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.7;
  z-index: 1;
  padding-bottom: 15px !important;
  padding-left: 15px !important;
}

.image {
  max-width: 100%;
  height: auto;
  max-height: 200px;
  display: block;

  @media (max-width: 575px) {
    max-height: none;
  }
}

.largeLoading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.smallLoading {
  padding-top: 25px;
}
