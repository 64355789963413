@import "../../shared/styles/colors.scss";

.organization {
  h1 {
    line-height: 25px;
  }

  .section {
    margin-top: 20px;

    &:not(:last-child) {
      border-bottom: 1px solid $gray_dark;
    }

    .subtitle {
      padding-bottom: 15px;
    }

    .list {
      margin-bottom: 20px;
      margin-left: 30px;
      line-height: 150%;

      &.gutterBottomItem {
        margin-bottom: 4px;

        li {
          margin-bottom: 16px;
        }
      }

      li {
        list-style-type: disc;
        display: list-item;
        padding: 1px 0;

        p {
          font-size: 16px;
        }
      }
    }
  }
}
