@import "../../../../shared/styles/colors";

.header {
  padding: 0 !important;
  overflow: hidden;

  .content {
    display: flex;
    padding: 0 !important;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .image {
      width: 100px;
      height: 100px;

      @media (max-width: 767px) {
        display: none;
      }

      img {
        width: 100px;
        height: 100px;
      }
    }

    .eventInfo {
      margin-left: 5px;
      padding: 15px 20px;
      flex: 1;
      &.fullInfo p {
        text-align: right;

        @media (max-width: 767px) {
          text-align: left;
        }
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      border-left: 1px solid $secondary;

      @media (max-width: 767px) {
        display: none;
      }

      .icon {
        flex: 1;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        ~ .icon {
          border-top: 1px solid $secondary;
        }
      }
    }
  }
}
