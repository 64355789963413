@import "../../../../shared/styles/colors.scss";

.divisor {
  border: 0;
  border-bottom: 1px solid $secondary;
  margin: 0;
}

.addCard {
  margin-top: 20px;
}

.refundReason {
  margin-top: 30px;

  .textarea {
    min-height: 80px;
  }
}
