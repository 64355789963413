.firstColButton {
  margin-top: 35px;
}

.button {
  width: 30px;
  height: 30px;
  padding: 0 !important;
}
.removeButtons {
  margin-top: 3px;
}
