.table {
  th:first-child,
  td:first-child {
    padding-left: 16px;
  }

  td:last-child {
    padding-right: 16px;
  }
}

.boxBadge {
  padding: 9px 0px;
}
